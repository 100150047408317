<template>
  <div>
    <div class="contentFrame">
      <div class="leftSide">
        <div class="subinfotext">サンタクロースからの手紙って？</div>

        <div class="sentent_r1">
          お父さんお母さんからお子様へ、<br />
          お爺ちゃん、お婆ちゃんから大切なお孫さんへ、<br />
          お友達、ご夫婦や恋人同士に…<br />
          フィンランドの公認サンタクロースより届く心温まる<br />
          素敵なクリスマスプレゼントです。

          <div class="centeimg1">
            <img src="../../assets/images/about1.png" alt="" />
          </div>
        </div>
        <div class="sentent_g1">
          ※お届け日時指定サービスは、11月30日ご注文完了分までの受付となります。なお、お支払い方法でコンビニ決済・銀行振込・郵便振替をご選択の場合、12月10日までにご入金が完了できたお客様のみ適用可能となります。
          ※お手紙一通あたり、本体価格1550円（税込）+クロネコゆうパケット送料(320円税込)となります。<br />
          また、ご依頼主様が直接事前に受け取りたい場合やお届け先様へ「日時指定を行いたい」というお客様からのご要望にお応えして、宅急便による日時指定配送サービスも開始致しました。＊宅急便による配達をご希望の場合には地域別宅急便送料を別途頂戴致します。
        </div>

        <div class="subsent2">
          <h3>「サンタクロースからの手紙」の内容とは・・</h3>
          <h4>フィンランド公認サンタクロースからのお手紙</h4>

          フィンランド公認サンタクロースからのメッセージを、クリスマスのイラストが描かれたオリジナル便箋・封筒にお入れして大切な方へお届けします。

          <div class="centeimg2">
            <img src="../../assets/images/about2.png" alt="" />
          </div>

          <h4>人気サンタクロースアーティストによるイラスト</h4>

          フィンランドの人気サンタクロースアーティスト、「ラッセ・カタイネン氏」による、優しく、温かいイラストが描かれた大きなオリジナル封筒・便箋でお届けいたします。便箋、封筒は毎年新しいイラストを使用。どんなデザインかわくわくしながらお待ち下さい。

          <div class="centeimg2">
            <img src="../../assets/images/about3.png" alt="" />
          </div>

          <h4>サンタクロースからの贈り物（クリスマスギフトも充実）</h4>
          サンタクロースからの手紙は、幅広い年齢の方にお届けできるよう、幼児向け・お子様向け・一般向けの3タイプの文章をご用意。それぞれ日本語・英語がご選択可能です。<br />
          *文章に英語をご選択の場合は、幼児から一般までそれぞれ難しい語句を使わず、楽しいわかりやすい内容となっています。<br />
          お手紙の内容は、お受取人様の呼びかけ名 (例：はなこちゃんへ)
          から始まり、サンタクロースからのメッセージが綴られています。文末にはご依頼主様にて「６０文字以内のメッセージ」も記載が可能です。（文末60文字メッセージは入力が無くてもお手紙文面は成立致しておりますのでご安心ください。）
          <br />
          *サンタクロースからのメッセージは毎年異なりますのでお届け時までお楽しみに！<br />
          また、ご注文時にご依頼主様のお名前を宛名シートに表示するか否か選択することが可能な為、お手紙の演出に幅を持たせる事ができます。誰がサンタさんに頼んでくれたのか分かるようにしたい場合は【宛名シートへ記載する】にチェックを入れてください。（お受取人様と同居されていない場合などにオススメです。）ご依頼主様のお名前を伏せてサプライズのお手紙を届けたい場合はチェックを外してください。

          <div class="centeimg2">
            <img src="../../assets/images/about4.png" alt="" />
          </div>

          お手紙の内容は、お受取人様の呼びかけ名 (例：はなこちゃんへ)
          から始まり、ご選択いただいた言語によるメッセージが記載されております。文末にはご依頼主様にてご入力頂ける「６０文字以内のメッセージ」も記載が可能です。<br />
          *濁点・句読点等も1文字となります。（文末60文字メッセージは入力が無くてもお手紙文面は成立致しておりますのでご安心ください。）<br />
          *サンタクロースからのメッセージは毎年異なり、文章内容についてはお届け時までのお楽しみとなります。<br />
          <br />
          誰がサンタさんに頼んでくれたのか分かるようにしたい場合は【宛名シートへ記載する】にチェックを入れてください。（お受取人様と同居されていない場合などにオススメです。）ご依頼主様のお名前を伏せてサプライズのお手紙を届けたい場合はチェックを外してください。

          <div class="centeimg2">
            <img src="../../assets/images/about5.png" alt="" />
          </div>

          <h4>サンタクロースからの贈り物（クリスマスギフトも充実）</h4>

          お手紙とともに「数量限定クリスマスギフト」もお贈り頂けます*。<br />
          フィンランド公認サンタクロースとサンタクロース大使館との共同企画による選りすぐりのクリスマスギフトは、毎年お受取人様に大変お喜び頂いております。
          <br />
          *追加にてご選択いただけるクリスマスギフトは別途料金となります。 <br />
          *クリスマスギフトは追加送料無しでご選択頂けるスモールサイズアイテムから、追加送料（宅急便料金）にてご選択頂けるアイテムまで多数お取り揃え致しております。
          <br />
          *クリスマスギフトは先着お申込み順となります。完売の際はご了承くださいませ。
        </div>
      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$parent.middle = 2;
  },
};
</script>

<style scoped lang="scss">
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

.subinfotext {
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center /
    contain;
  @include sp {
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1 {
  color: #d72e23;
  line-height: 1.7;
  margin: 2em 0;
}
.sentent_g1 {
  text-align: left;
  color: #454552;
  line-height: 1.7;
  margin: 2em 0;
}
.subsent2 {
  margin-bottom: 2em;
  text-align: left;
  border: solid 3px #d72e23;
  padding: 1em;
  h3 {
    color: #d72e23;
    padding: 2em 0;
  }
  h4 {
    color: #d72e23;
    margin: 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
}
.centeimg1 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 33%;
    max-width: 100%;
  }
}
.centeimg2 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 90%;
    max-width: 100%;
  }
}
</style>